import React from 'react';

interface CaretRightProps {
   width?: number;
   height?: number;
}

const CaretRight: React.FC<CaretRightProps> = ({ width = 10, height = 10 }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 11"
      fill="none"
   >
      <path
         d="M7.09627 5.72115L3.97127 8.84615C3.94223 8.87518 3.90776 8.89821 3.86983 8.91392C3.83189 8.92964 3.79123 8.93773 3.75017 8.93773C3.70911 8.93773 3.66845 8.92964 3.63052 8.91392C3.59258 8.89821 3.55811 8.87518 3.52908 8.84615C3.50005 8.81711 3.47701 8.78264 3.4613 8.74471C3.44559 8.70677 3.4375 8.66611 3.4375 8.62505C3.4375 8.58399 3.44559 8.54333 3.4613 8.5054C3.47701 8.46746 3.50005 8.43299 3.52908 8.40396L6.43338 5.50005L3.52908 2.59615C3.47044 2.53751 3.4375 2.45798 3.4375 2.37505C3.4375 2.29213 3.47044 2.2126 3.52908 2.15396C3.58772 2.09532 3.66725 2.06238 3.75017 2.06238C3.8331 2.06238 3.91263 2.09532 3.97127 2.15396L7.09627 5.27896C7.12532 5.30798 7.14837 5.34245 7.1641 5.38038C7.17983 5.41832 7.18792 5.45898 7.18792 5.50005C7.18792 5.54112 7.17983 5.58178 7.1641 5.61972C7.14837 5.65766 7.12532 5.69212 7.09627 5.72115Z"
         fill="#2F2E31"
      />
   </svg>
);

export default CaretRight;
