import React from 'react';
import ShrimpSvg from '../assets/svg/shrimp';
import formatCurrency from './currencyConverter';
import {
   SelectCurrency,
   TransactionType,
} from '../pages/Projects/type/ProjectTypes';
import FishSvg from '../assets/svg/fish';
import DolphinSvg from '../assets/svg/dolphin';
import WhaleSvg from '../assets/svg/whale';
import buySellTextColorRender from './buySellTextColorRender';

const amountWithIconRender = (
   size: number,
   currency: SelectCurrency = 'USD',
   type: TransactionType | '' = '',
) => {
   const className = `flex gap-2 justify-end items-center ${buySellTextColorRender(type)}`;
   switch (true) {
      case size <= 1000:
         return (
            <div className={className}>
               {formatCurrency(size, currency)}
               <ShrimpSvg />
            </div>
         );
      case size > 1000 && size <= 3000:
         return (
            <div className={className}>
               {formatCurrency(size, currency)}
               <FishSvg />
            </div>
         );
      case size > 3000 && size <= 10000:
         return (
            <div className={className}>
               {formatCurrency(size, currency)}
               <DolphinSvg />
            </div>
         );
      case size > 10000:
         return (
            <div className={className}>
               {formatCurrency(size, currency)}
               <WhaleSvg />
            </div>
         );
      default:
         return <div></div>;
   }
};

export default amountWithIconRender;
