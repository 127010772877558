/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Select } from 'antd';
import { getTotalInWalletSyncswapByProjectID } from '../../../services/projectServices';
import { useParams } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { SyncOutlined } from '@ant-design/icons';
import LoadingComponent from '../../../components/LoadingComponent';
import OverviewInfo from '../../../components/OverviewInfo';
import { Project } from '../../../redux/projects/type';
import HeadingWithTooltipComponent from '../../../components/TitleWithTooltipComponent';
import { SelectCurrencySyncswap } from '../type/ProjectTypes';

interface CalculateInWalletResponse {
   totalZK: string;
   totalZKByUsd: string;
   totalETH: string;
   totalETHByUsd: string;
   balanceETHTradeContract: string;
   balanceETHTradeContractByUsd: string;
   balanceETHOperatorManual: string;
   balanceETHOperatorManualByUsd: string;
   balanceETHOwner: string;
   balanceETHOwnerByUsd: string;
   balanceETHTrader: string;
   balanceETHTraderByUsd: string;
   balanceETHOperatorAuto: string;
   balanceETHOperatorAutoByUsd: string;
   tokenOnWallet: string;
   totalOperatorManualWalletZK: string;
   totalOperatorManualWalletZKByUsd: string;
   totalOwnerWalletZK: string;
   totalOwnerWalletZKByUsd: string;
   totalTraderBotZK: string;
   totalTraderBotZKByUsd: string;
   totalOperatorAutoWalletZK: string;
   totalOperatorAutoWalletZKByUsd: string;
   totalTradeContractZK: string;
   totalTradeContractZKByUsd: string;
   balanceTokenTrader: string;
   balanceTokenOperatorAuto: string;
   balanceTokenOperatorManual: string;
   balanceTokenOwner: string;
   balanceTokenTradeContract: string;
   zkUsdPrice: string;
}

interface ProjectCalculateInWalletComponentProps {
   project: Project | null;
   projectLoading: boolean;
}

const ProjectCalculateInWalletComponent: React.FC<
   ProjectCalculateInWalletComponentProps
> = ({ project, projectLoading }) => {
   const [overviewCurrency, setOverviewCurrency] =
      useState<SelectCurrencySyncswap>('ZK');

   const [inWalletData, setInWalletData] =
      useState<CalculateInWalletResponse | null>(null);

   const [fetching, setFetching] = useState<boolean>(false);

   const params = useParams();

   const isFetchingRef = useRef(false);

   const fetchPnl = async () => {
      if (!params?.id || fetching || isFetchingRef.current) {
         return;
      }
      setFetching(true);
      isFetchingRef.current = true;
      try {
         const resPnl = await getTotalInWalletSyncswapByProjectID(
            params?.id ? +params.id : 0,
         );
         setFetching(false);
         isFetchingRef.current = false;
         setInWalletData(resPnl?.data?.data);
      } catch (err) {
         setFetching(false);
         isFetchingRef.current = false;
      }
   };

   useEffect(() => {
      fetchPnl();
      const intervalId = setInterval(fetchPnl, 5000);
      return () => clearInterval(intervalId);
   }, []);

   return (
      <>
         <div className="flex justify-between items-center gap-3 mt-[7px] mb-[23px] flex-wrap">
            <Title className="!mb-0 max-sm:w-full" level={3}>
               In Wallet
            </Title>
            <div className="flex gap-4 items-center max-sm:flex-row-reverse">
               {fetching && inWalletData?.totalZK && (
                  <SyncOutlined spin style={{ fontSize: 24 }} />
               )}
               <Select
                  disabled={projectLoading}
                  value={overviewCurrency}
                  onChange={(value: SelectCurrencySyncswap) =>
                     setOverviewCurrency(value)
                  }
                  className="w-24 shadow-small rounded-[10px]"
               >
                  <Select.Option value="USD">USDT</Select.Option>
                  <Select.Option value="ZK">Token</Select.Option>
               </Select>
            </div>
         </div>
         <Title level={5} className="!mb-[16px]">
            Sumary
         </Title>
         <Card>
            <Row gutter={[20, 20]}>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ZK On Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={inWalletData?.totalZKByUsd || '0'}
                        />
                     ) : (
                        <OverviewInfo amountZK={inWalletData?.totalZK || '0'} />
                     )}
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                     size={30}
                  >
                     <HeadingWithTooltipComponent>{`${project?.name} On Wallet`}</HeadingWithTooltipComponent>
                     <OverviewInfo
                        usdSymbolHidden={true}
                        amountUsd={inWalletData?.tokenOnWallet || '0'}
                     />
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ETH On Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={inWalletData?.totalETHByUsd || '0'}
                        />
                     ) : (
                        <OverviewInfo
                           amountEth={inWalletData?.totalETH || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>
            </Row>
         </Card>
         <Title level={5} className="!my-[16px]">
            Detail ZK
         </Title>
         <Card className="mt-4">
            <Row gutter={[20, 20]}>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ZK On Owner Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={
                              inWalletData?.totalOwnerWalletZKByUsd || '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           amountZK={inWalletData?.totalOwnerWalletZK || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>

               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ZK On Operator Manual Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={
                              inWalletData?.totalOperatorManualWalletZKByUsd ||
                              '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           amountZK={
                              inWalletData?.totalOperatorManualWalletZK || '0'
                           }
                        />
                     )}
                  </LoadingComponent>
               </Col>

               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ZK On Operator Auto Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={
                              inWalletData?.totalOperatorAutoWalletZKByUsd ||
                              '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           amountZK={
                              inWalletData?.totalOperatorAutoWalletZK || '0'
                           }
                        />
                     )}
                  </LoadingComponent>
               </Col>

               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ZK On Trade Contract
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={
                              inWalletData?.totalTradeContractZKByUsd || '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           amountZK={inWalletData?.totalTradeContractZK || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>

               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ZK On Bot Trader Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={
                              inWalletData?.totalTraderBotZKByUsd || '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           amountZK={inWalletData?.totalTraderBotZK || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>
            </Row>
         </Card>
         <Title level={5} className="!my-[16px]">
            {`Detail ${project?.name}`}
         </Title>

         <Card className="mt-4">
            <Row gutter={[20, 20]}>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        {`${project?.name} On Owner Wallet`}
                     </HeadingWithTooltipComponent>
                     <OverviewInfo
                        usdSymbolHidden={true}
                        amountUsd={inWalletData?.balanceTokenOwner || '0'}
                     />
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        {`${project?.name} On Operator Manual Wallet`}
                     </HeadingWithTooltipComponent>
                     <OverviewInfo
                        usdSymbolHidden={true}
                        amountUsd={
                           inWalletData?.balanceTokenOperatorManual || '0'
                        }
                     />
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        {`${project?.name} On Operator Auto Wallet`}
                     </HeadingWithTooltipComponent>
                     <OverviewInfo
                        usdSymbolHidden={true}
                        amountUsd={
                           inWalletData?.balanceTokenOperatorAuto || '0'
                        }
                     />
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        {`${project?.name} On Trade Contract`}
                     </HeadingWithTooltipComponent>
                     <OverviewInfo
                        usdSymbolHidden={true}
                        amountUsd={
                           inWalletData?.balanceTokenTradeContract || '0'
                        }
                     />
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        {`${project?.name} On Bot Trader Wallet`}
                     </HeadingWithTooltipComponent>
                     <OverviewInfo
                        usdSymbolHidden={true}
                        amountUsd={inWalletData?.balanceTokenTrader || '0'}
                     />
                  </LoadingComponent>
               </Col>
            </Row>
         </Card>
         <Title level={5} className="!my-[16px]">
            Detail ETH
         </Title>
         <Card className="mt-4">
            <Row gutter={[20, 20]}>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ETH On Owner Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={inWalletData?.balanceETHOwnerByUsd || '0'}
                        />
                     ) : (
                        <OverviewInfo
                           amountEth={inWalletData?.balanceETHOwner || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>

               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ETH On Operator Manual Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={
                              inWalletData?.balanceETHOperatorManualByUsd || '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           amountEth={
                              inWalletData?.balanceETHOperatorManual || '0'
                           }
                        />
                     )}
                  </LoadingComponent>
               </Col>

               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ETH On Operator Auto Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={
                              inWalletData?.balanceETHOperatorAutoByUsd || '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           amountEth={
                              inWalletData?.balanceETHOperatorAuto || '0'
                           }
                        />
                     )}
                  </LoadingComponent>
               </Col>

               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ETH On Trade Contract
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={
                              inWalletData?.balanceETHTradeContractByUsd || '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           amountEth={
                              inWalletData?.balanceETHTradeContract || '0'
                           }
                        />
                     )}
                  </LoadingComponent>
               </Col>

               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={inWalletData?.totalZK ? false : fetching}
                  >
                     <HeadingWithTooltipComponent>
                        ETH On Bot Trader Wallet
                     </HeadingWithTooltipComponent>
                     {overviewCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={
                              inWalletData?.balanceETHTraderByUsd || '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           amountEth={inWalletData?.balanceETHTrader || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>
            </Row>
         </Card>
      </>
   );
};
export default ProjectCalculateInWalletComponent;
