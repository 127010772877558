import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';
import { Skeleton } from 'antd';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';

const ChartGenerator2Component = lazy(
   () => import('../ChartGenerator2Component'),
);

const ChartGeneratorListComponent = lazy(
   () => import('../ChartGeneratorListComponent'),
);

const ChartGeneratorRouterComponent = () => {
   const { hasPermission } = usePermissions();

   return (
      <Suspense
         fallback={
            <Skeleton
               className="container py-5 mx-auto h-full"
               paragraph={{ rows: 6 }}
               active
            />
         }
      >
         <Routes>
            <Route
               path="/"
               element={
                  <PrivateRoute
                     hasPermission={hasPermission(PermissionName.MMLIST_VIEW)}
                     element={<ChartGeneratorListComponent />}
                  />
               }
            />
            <Route
               path=":id"
               element={
                  <PrivateRoute
                     hasPermission={hasPermission(
                        PermissionName.MMGENERATOR_VIEW,
                     )}
                     element={<ChartGenerator2Component />}
                  />
               }
            />
         </Routes>
      </Suspense>
   );
};

export default ChartGeneratorRouterComponent;
