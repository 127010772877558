/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Text from 'antd/es/typography/Text';
import React from 'react';

interface AddressCopyableComponentProps {
   text: string;
}

const AddressCopyableComponent: React.FC<AddressCopyableComponentProps> = ({
   text,
}) => {
   return text?.length < 8 ? (
      <Paragraph className="!mb-0 whitespace-nowrap" copyable>
         {text}
      </Paragraph>
   ) : (
      <div className="flex gap-1">
         <Tooltip
            title={text}
            overlayInnerStyle={{ width: '340px', textAlign: 'center' }}
         >
            <div>
               {text?.slice(0, 4)}
               ...
               {text?.slice(text.length - 4, text.length)}
            </div>
         </Tooltip>

         <Text copyable={{ text }} />
      </div>
   );
};

export default AddressCopyableComponent;
