import React from 'react';

const OpenSvg: React.FC = () => (
   <svg
      height="20px"
      width="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M5 12V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H12M8.11111 12H12M12 12V15.8889M12 12L5 19"
         stroke="#464455"
      />
   </svg>
);

export default OpenSvg;
