import { FilterAnimals } from './filterIconRender';

const rangeAmount = (animal: FilterAnimals) => {
   switch (animal) {
      case 'shrimp':
         return {
            min: 0,
            max: 1000,
         };
      case 'fish':
         return {
            min: 1001,
            max: 3000,
         };
      case 'dolphin':
         return {
            min: 3001,
            max: 10000,
         };
      case 'whale':
         return {
            min: 10001,
         };
      default:
         return {};
   }
};
export default rangeAmount;
