import React, { ReactNode } from 'react';
import Infor from '../assets/svg/infor';
import { Tooltip } from 'antd';

interface HeadingWithTooltipComponentProps {
   tooltip?: string;
   children?: ReactNode;
   className?: string;
}

const HeadingWithTooltipComponent: React.FC<
   HeadingWithTooltipComponentProps
> = ({ children, className = '', tooltip = '' }) => {
   return (
      <div
         className={`title-with-tooltip flex gap-[5px] items-center ${className}`}
      >
         {children}
         {tooltip && (
            <Tooltip title={tooltip} placement="bottom">
               <div>
                  <Infor />
               </div>
            </Tooltip>
         )}
      </div>
   );
};

export default HeadingWithTooltipComponent;
