import { TransactionType } from '../pages/Projects/type/ProjectTypes';

export const TransactionTypes: { [K in TransactionType]: string } = {
   sell: 'Sell',
   buy: 'Buy',
   SELL: 'Sell',
   BUY: 'Buy',
   transfer_eth: 'Transfer ETH',
   transfer_token: 'Transfer Token',
};
