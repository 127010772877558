import React from 'react';

interface InforProps {
   width?: number;
   height?: number;
}

const Infor: React.FC<InforProps> = ({ width = 17, height = 17 }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
   >
      <path
         d="M8.66663 2C7.38105 2 6.12434 2.38122 5.05542 3.09545C3.9865 3.80968 3.15338 4.82484 2.66141 6.01256C2.16944 7.20028 2.04072 8.50721 2.29152 9.76809C2.54233 11.029 3.16139 12.1872 4.07043 13.0962C4.97948 14.0052 6.13767 14.6243 7.39854 14.8751C8.65942 15.1259 9.96635 14.9972 11.1541 14.5052C12.3418 14.0132 13.357 13.1801 14.0712 12.1112C14.7854 11.0423 15.1666 9.78558 15.1666 8.5C15.1648 6.77665 14.4794 5.12441 13.2608 3.90582C12.0422 2.68722 10.39 2.00182 8.66663 2ZM8.66663 14C7.57883 14 6.51546 13.6774 5.61099 13.0731C4.70652 12.4687 4.00157 11.6098 3.58529 10.6048C3.16901 9.59977 3.06009 8.4939 3.27231 7.427C3.48453 6.36011 4.00835 5.3801 4.77754 4.61091C5.54673 3.84172 6.52674 3.3179 7.59363 3.10568C8.66053 2.89346 9.76639 3.00238 10.7714 3.41866C11.7764 3.83494 12.6354 4.53989 13.2397 5.44436C13.8441 6.34883 14.1666 7.4122 14.1666 8.5C14.165 9.95818 13.585 11.3562 12.5539 12.3873C11.5228 13.4184 10.1248 13.9983 8.66663 14ZM9.66663 11.5C9.66663 11.6326 9.61395 11.7598 9.52018 11.8536C9.42641 11.9473 9.29924 12 9.16663 12C8.90141 12 8.64706 11.8946 8.45952 11.7071C8.27199 11.5196 8.16663 11.2652 8.16663 11V8.5C8.03402 8.5 7.90684 8.44732 7.81308 8.35355C7.71931 8.25979 7.66663 8.13261 7.66663 8C7.66663 7.86739 7.71931 7.74021 7.81308 7.64645C7.90684 7.55268 8.03402 7.5 8.16663 7.5C8.43185 7.5 8.6862 7.60536 8.87374 7.79289C9.06127 7.98043 9.16663 8.23478 9.16663 8.5V11C9.29924 11 9.42641 11.0527 9.52018 11.1464C9.61395 11.2402 9.66663 11.3674 9.66663 11.5ZM7.66663 5.75C7.66663 5.60166 7.71062 5.45666 7.79303 5.33332C7.87544 5.20999 7.99257 5.11386 8.12962 5.05709C8.26666 5.00032 8.41746 4.98547 8.56295 5.01441C8.70843 5.04335 8.84207 5.11478 8.94696 5.21967C9.05185 5.32456 9.12328 5.4582 9.15222 5.60368C9.18116 5.74917 9.1663 5.89997 9.10954 6.03701C9.05277 6.17406 8.95664 6.29119 8.83331 6.3736C8.70997 6.45601 8.56496 6.5 8.41663 6.5C8.21772 6.5 8.02695 6.42098 7.8863 6.28033C7.74565 6.13968 7.66663 5.94891 7.66663 5.75Z"
         fill="#787878"
      />
   </svg>
);

export default Infor;
