import React, { useEffect } from 'react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from '../../../components/LoadingComponent';
import { RootState, useAppDispatch } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { fetchProjectById } from '../../../redux/projects/projectsSlice';
import ProjectPnLComponent from './ProjectPnLComponent';
import { Tabs } from 'antd';
import ProjectCalculateInWalletComponent from './ProjectCalculateInWalletComponent';
import ProjectUserTransactionComponent from './ProjectUserTransactionComponent';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';

const ProjectSyncswapMonitorComponent = () => {
   const params = useParams();
   const { project, loading: projectLoading } = useSelector(
      (state: RootState) => state.projects,
   );

   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { hasPermission } = usePermissions();

   const projectMonitorBreadcrumbs = [
      {
         title: 'All Projects',
         link: '/project',
      },
      {
         title: (
            <LoadingComponent size={20} loading={projectLoading}>
               {project?.name}
            </LoadingComponent>
         ),
         link: `/project-syncswap/${params?.id}/monitor`,
      },
      {
         title: 'Monitor',
      },
   ];

   useEffect(() => {
      if (!project && !projectLoading && params?.id) {
         dispatch(fetchProjectById(+params?.id, navigate));
      }
   }, [dispatch, project, projectLoading]);

   return (
      <div className="projects-monitor-component pb-8">
         <BreadcrumbComponent items={projectMonitorBreadcrumbs} />
         <Tabs defaultActiveKey="1" destroyInactiveTabPane>
            <Tabs.TabPane tab="Overview" key="1">
               <ProjectPnLComponent
                  project={project}
                  projectLoading={projectLoading}
               />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="Top buyers" key="2">
               <ProjectTopBuyerComponent />
            </Tabs.TabPane> */}
            {hasPermission(PermissionName.ACCESS_UNISWAP) && (
               <Tabs.TabPane tab="User transactions" key="3">
                  <ProjectUserTransactionComponent />
               </Tabs.TabPane>
            )}
            <Tabs.TabPane tab="In Wallets" key="4">
               <ProjectCalculateInWalletComponent
                  project={project}
                  projectLoading={projectLoading}
               />
            </Tabs.TabPane>
         </Tabs>
         {/* <ProjectLockSellComponent /> */}
      </div>
   );
};

export default ProjectSyncswapMonitorComponent;
