import BigNumber from 'bignumber.js';

const overviewTextColorRender = (
   value: string | undefined,
   isRevert?: boolean,
) => {
   const num = new BigNumber(value || '0');

   switch (true) {
      case num.isGreaterThan(0):
         return isRevert ? 'text-[#EB3737]' : 'text-[#15BD7A]';
      case num.isLessThan(0):
         return isRevert ? 'text-[#15BD7A]' : 'text-[#EB3737]';
      default:
         return '';
   }
};

export default overviewTextColorRender;
