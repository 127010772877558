import React from 'react';

interface ZKSyncSvgProps {
   width?: number;
   height?: number;
}

const ZKSyncSvg: React.FC<ZKSyncSvgProps> = ({ width = 30, height = 30 }) => (
   <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 400 400"
      xmlSpace="preserve"
   >
      <style>
         {`
       .st0 { fill-rule:evenodd; clip-rule:evenodd; fill:#FFFFFF; }
       .st1 { fill-rule:evenodd; clip-rule:evenodd; }
     `}
      </style>
      <rect className="st0" width="400" height="400" />
      <g>
         <path
            className="st1"
            d="M316,199l-66.7-66.4v48.6l-66.2,48.7l66.2,0v35.5L316,199z"
         />
         <path
            className="st1"
            d="M81,199l66.7,66.4v-48.3l66.2-49.1l-66.2,0v-35.5L81,199z"
         />
      </g>
   </svg>
);

export default ZKSyncSvg;
