/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axios/axiosInstance';
import axiosInstanceForPredictHub from '../axios/axiosInstanceForPredictHub';

export const getTopbuyers = (params: any, id: number) => {
   return axiosInstance.get(`/v1/accounts/top-buyers/${id}`, {
      params,
   });
};

export const getUserTransactions = (params: any, id: number) => {
   return axiosInstance.get(`/v1/transactions/user-transaction/${id}`, {
      params,
   });
};

export const getStategyUserTransactions = (params: any) => {
   return axiosInstanceForPredictHub.get(`/v1/orders/strategy`, {
      params,
   });
};
