import { SelectCurrency } from '../pages/Projects/type/ProjectTypes';
import numeral from 'numeral';

function formatCurrency(amount: number, currency: SelectCurrency): string {
   if (amount === undefined || amount === null || Number.isNaN(amount)) {
      return '';
   }
   if (
      (Math.abs(amount) < 1e-6 && currency === 'ETH') ||
      (Math.abs(amount) < 1e-2 && currency === 'USD')
   ) {
      return currency === 'USD' ? '< 0.01' : '< 0.000001';
   }
   if (currency !== 'USD' && currency !== 'ETH') {
      if (amount < 1) {
         return '~ 0';
      }
      return numeral(amount).format();
   }
   if (Math.abs(amount) < 1) {
      return numeral(amount).format(
         currency === 'USD' ? '0.[00]' : '0.[000000]',
      );
   }
   return numeral(amount).format(
      currency === 'USD' ? '0,0.[00]' : '0,0.[000000]',
   );
}

export default formatCurrency;
