/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
   AxiosInstance,
   AxiosResponse,
   InternalAxiosRequestConfig,
} from 'axios';
import axiosRetry from 'axios-retry';
import { KEYS, get } from '../utils/localStorage';
import { logout } from '../redux/auth/authSlice';
import { message } from 'antd';
import { NavigateFunction } from 'react-router-dom';

const axiosInstanceForPredictHub: AxiosInstance = axios.create({
   // baseURL: 'http://phoenix-api.aquafox.io',
   baseURL: 'https://predicthub-api.aquafox.io',
   httpsAgent: false, // Disable HTTPS
   headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'C02t69b6ZAMrT8XtgNAfqYXyMPR7nKHrRJ3',
   },
});
axiosInstanceForPredictHub.interceptors.request.use(
   (config: InternalAxiosRequestConfig) => {
      const token = get(KEYS.TOKEN_PREDICTHUB); // Or get the token from a Redux store
      if (token) {
         config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
   },
   (error) => {
      return Promise.reject(error);
   },
);

export const setupInterceptors = (store: any, navigate: NavigateFunction) => {
   axiosInstanceForPredictHub.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: any) => {
         if (error?.response?.data?.statusCode === 401) {
            store.dispatch(logout());
            navigate('/login');
            message.warning('Session Timed Out! Please Re-Login!!!');
            return Promise.reject();
         }
         return Promise.reject(error);
      },
   );
};

axiosRetry(axiosInstanceForPredictHub, {
   retries: 1,
   // TODO: define error code to handle retry
   retryCondition: (error) => {
      return error.response?.status === 500;
   },
   retryDelay: (retryCount) => {
      return retryCount * 200;
   },
});

// refresh token
axiosInstanceForPredictHub.interceptors.response.use(
   (response) => {
      return response;
   },
   async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
         originalRequest._retry = true;
         // Get the refresh token from localStorage or Redux store
         // Make a call to refresh the token
         const loginRes = await axiosInstanceForPredictHub.post(
            '/v1/auth/login',
            {
               username: 'username',
               password: 'Password@123',
            },
         );
         const token = loginRes?.data?.data?.access_token;
         // Store the new token
         localStorage.setItem(KEYS.TOKEN_PREDICTHUB, token);
         // Update the Authorization header with the new token
         axiosInstanceForPredictHub.defaults.headers.Authorization = `Bearer ${token}`;
         originalRequest.headers.Authorization = `Bearer ${token}`;
         // Retry the original request
         return axiosInstanceForPredictHub(originalRequest);
      }
      return Promise.reject(error);
   },
);

export default axiosInstanceForPredictHub;
