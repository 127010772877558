/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

export function dateTimeStampConverter(timestamp: number): string {
   return moment(timestamp * 1000).format('HH:mm DD/MM/yyyy');
}

export function dateConverter(date: any): string {
   return moment(date).format('HH:mm DD/MM/yyyy');
}

export default dateTimeStampConverter;
