/* eslint-disable @typescript-eslint/no-explicit-any */
import BigNumber from 'bignumber.js';

function formatTokenPrice(value: any) {
   const num = new BigNumber(value); // Sử dụng BigNumber để đảm bảo độ chính xác

   // Chuyển số thành chuỗi thập phân đầy đủ
   const str = num.toFixed(18); // Sử dụng tối thiểu 18 chữ số sau dấu thập phân
   const parts = str.split('.'); // Tách phần nguyên và phần thập phân

   if (parts.length === 2) {
      const decimals = parts[1]; // Phần thập phân
      const leadingZeros = decimals.match(/^0+/); // Đếm số 0 liên tiếp ở đầu
      const zeroCount = leadingZeros ? leadingZeros[0].length : 0; // Số lượng số 0

      // Xử lý khi số nhỏ (ví dụ: 1.804995378452e-8)
      let firstDigit = decimals[zeroCount]; // Chữ số đầu tiên sau các số 0
      if (firstDigit === '0') {
         // Nếu chữ số đầu tiên là 0, tìm chữ số khác không phải 0
         firstDigit = decimals[zeroCount + 1] || ''; // Chữ số tiếp theo không phải 0
      }

      const nextDigits = decimals.substring(zeroCount + 1, zeroCount + 5); // Lấy tối đa 4 chữ số tiếp theo

      // Trả về số gốc nếu không có chữ số nào đáng chú ý sau số 0
      if (!firstDigit || Number(firstDigit) === 0) {
         return num.toString(); // Trả về số ban đầu nếu không có chữ số đáng chú ý
      }

      // Định dạng với subscript cho số 0
      return `0.0(${zeroCount})${firstDigit}${nextDigits}`;
   }

   return num.toString(); // Trả về số gốc nếu không có phần thập phân
}

export default formatTokenPrice;
