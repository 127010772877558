/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { getStrateryByID } from '../../services/strategyServices';

type StrateryState = {
   stratery: any | null;
   loading: boolean;
   error: string | null;
};

const initialState: StrateryState = {
   stratery: null,
   loading: false,
   error: null,
};

const straterysSlice = createSlice({
   name: 'straterys',
   initialState,
   reducers: {
      setStratery(state, action: PayloadAction<any | null>) {
         state.stratery = action.payload;
         state.loading = false;
         state.error = null;
      },
      setLoading(state, action: PayloadAction<boolean>) {
         state.loading = action.payload;
      },
      setError(state, action: PayloadAction<string | null>) {
         state.error = action.payload;
         state.loading = false;
      },
   },
});

export const { setStratery, setLoading, setError } = straterysSlice.actions;

export default straterysSlice.reducer;

export const fetchStrateryById =
   (id: number, navigate: (path: string) => void): AppThunk =>
   async (dispatch) => {
      dispatch(setLoading(true));
      try {
         const resProj = await getStrateryByID(id);
         console.log(resProj?.data?.data, 123123);

         const stratery = resProj?.data?.data as any;

         dispatch(setStratery(stratery));
      } catch (err) {
         navigate('/stratery');
         dispatch(setStratery(null));
      } finally {
         dispatch(setLoading(false));
      }
   };
