import React from 'react';

const DolphinSvg: React.FC = () => (
   <svg
      role="img"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      height={18}
      width={18}
      data-icon="dolphin"
      fill="#818EA1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
   >
      <path
         fill="#818EA1"
         d="M477.3 24.9c3.5-5.3 3.6-12.2 .1-17.6S467.7-.8 461.4 .2c-16.7 2.8-43.1 8.6-68.8 17C378 22 362.5 28 349 35.3C306.5 12.8 258 0 206.5 0H176C96.5 0 32 64.5 32 144c0 18.6 3.5 36.4 10 52.7L20.5 209.6C7.8 217.2 0 231 0 245.8C0 269.1 18.9 288 42.2 288H176h16 11.2l92 61.3c4.9 3.3 11.2 3.6 16.4 .8s8.5-8.2 8.5-14.1V288h6.6c31.7 0 57.4 25.7 57.4 57.4c0 30.6-24 55.8-54.5 57.3l-53.4 2.7c-20.2-33-56.3-53.4-95.4-53.4H176c-5.5 0-10.7 2.9-13.6 7.6s-3.2 10.6-.7 15.6L190.1 432l-28.4 56.8c-2.5 5-2.2 10.8 .7 15.6s8.1 7.6 13.6 7.6h4.7c35.9 0 69.2-17.2 90.1-45.4l68 3.8c94.1 5.2 173.3-70 173.3-164.3c0-80.8-31.4-154.5-82.7-209.3l48-72zM176 120a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
      ></path>
   </svg>
);

export default DolphinSvg;
