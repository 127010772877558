import { Footer } from 'antd/es/layout/layout';
import React, { RefObject } from 'react';

interface FooterComponentProps {
   footerRef: RefObject<HTMLDivElement>;
}

const FooterComponent: React.FC<FooterComponentProps> = ({ footerRef }) => {
   return (
      <Footer className="shadow-medium bg-[#fff]" ref={footerRef}>
         <div className="container footer flex-wrap gap-3 max-sm:justify-center">
            <a
               href="https://aquafox.io"
               target="_blank"
               rel="noopener, noreferrer"
            >
               <img
                  className="logo-img cursor-pointer"
                  src="/images/common/logo_v2.png"
               />
            </a>
            <div className="copy-right max-sm:text-center">
               Copyright ©{new Date().getFullYear()} AquaFox JSC. All rights
               reserved.
            </div>
         </div>
      </Footer>
   );
};

export default FooterComponent;
