import React from 'react';

interface FilterSvgProps {
   isFilter?: boolean;
}

const FilterSvg: React.FC<FilterSvgProps> = ({ isFilter = false }) => (
   <svg
      fill={isFilter ? '#2C88D9' : '#686868'}
      height="14px"
      width="14px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
   >
      <g>
         <g>
            <polygon points="0,0 0,128 201.143,329.143 201.143,512 310.857,475.429 310.857,329.143 512,128 512,0" />
         </g>
      </g>
   </svg>
);
export default FilterSvg;
