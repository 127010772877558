/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from 'antd';
import React from 'react';
import FilterSvg from '../assets/svg/filter';
import { RangeValue, SearchParam } from '../pages/Projects/type/ProjectTypes';
import ShrimpSvg from '../assets/svg/shrimp';
import FishSvg from '../assets/svg/fish';
import DolphinSvg from '../assets/svg/dolphin';
import WhaleSvg from '../assets/svg/whale';

export type FilterType = 'range' | 'text' | 'animal' | 'multiple';
export type FilterAnimals =
   | 'shrimp'
   | 'fish'
   | 'dolphin'
   | 'whale'
   | 'Shrimp'
   | 'Fish'
   | 'Dolphin'
   | 'Whale'
   | string;

export const FilterIconRender = (
   searchParams: SearchParam[],
   dataIndex: string,
   type: FilterType = 'range',
) => {
   const paramSearch = searchParams.find((s) => s.type === dataIndex);
   const hasParamSearch = !!paramSearch?.type;
   let toolTip: any | string = '';
   switch (type) {
      case 'range':
         toolTip = rangeSearchToolTipRender(paramSearch);
         break;
      case 'animal':
         toolTip = animalToolTipRender(paramSearch);
         break;
      case 'text':
         toolTip = textSearchToolTipRender(paramSearch);
         break;
      case 'multiple':
         toolTip = multipleSearchToolTipRender(paramSearch);
         break;
      default:
         break;
   }
   return (
      <Tooltip title={hasParamSearch ? toolTip : ''}>
         <div>
            <FilterSvg isFilter={hasParamSearch} />
         </div>
      </Tooltip>
   );
};

const rangeSearchToolTipRender = (rangeValue: SearchParam | undefined) => {
   if (
      rangeValue?.value &&
      typeof rangeValue.value === 'object' &&
      'min' in rangeValue.value &&
      'max' in rangeValue.value
   ) {
      const { min, max } = rangeValue.value as RangeValue;
      return `From ${min} to ${max}`;
   }
   return '';
};

const animalToolTipRender = (animalValue: SearchParam | undefined) => {
   if (
      animalValue &&
      animalValue.value &&
      typeof animalValue.value === 'string'
   ) {
      const value = animalValue.value as FilterAnimals;
      switch (value) {
         case 'shrimp':
            return <ShrimpFilter />;
         case 'fish':
            return <FishFilter />;
         case 'dolphin':
            return <DolphinFilter />;
         case 'whale':
            return <WhaleFilter />;
         default:
            return '';
      }
   }
};

const textSearchToolTipRender = (textValue: SearchParam | undefined) => {
   if (textValue?.value) {
      return textValue?.value;
   }
   return '';
};

const multipleSearchToolTipRender = (textValue: SearchParam | undefined) => {
   if (textValue?.value && Array.isArray(textValue.value)) {
      return textValue?.value.join(', ');
   }
   return '';
};

export const ShrimpFilter = () => (
   <div className="flex gap-2 items-center">
      <ShrimpSvg />
      Shrimp (&lt;$1K)
   </div>
);

export const FishFilter = () => (
   <div className="flex gap-2 items-center">
      <FishSvg />
      Fish ($1K - $3K)
   </div>
);

export const DolphinFilter = () => (
   <div className="flex gap-2 items-center">
      <DolphinSvg />
      Dolphin ($3K - $10K)
   </div>
);

export const WhaleFilter = () => (
   <div className="flex gap-2 items-center">
      <WhaleSvg />
      Whale (&gt;$10K)
   </div>
);
