/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { SelectCurrencySyncswap } from '../type/ProjectTypes';
import { Card, Col, Row, Select } from 'antd';
import { getPnlSyncswapByProjectID } from '../../../services/projectServices';
import { useParams } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { SyncOutlined } from '@ant-design/icons';
import LoadingComponent from '../../../components/LoadingComponent';
import OverviewInfo from '../../../components/OverviewInfo';
import { Project } from '../../../redux/projects/type';
import HeadingWithTooltipComponent from '../../../components/TitleWithTooltipComponent';
import overviewTextColorRender from '../../../utils/overviewTextColorRender';
import UpdateExpenseComponent, { ExpenseType } from './UpdateExpenseComponent';

interface PnlResponse {
   currentZK: string;
   currentZKByUSD: string;
   profitZK: string;
   profitZKByUSD: string;
   totalCostZK: string;
   totalCostZKByUSD: string;
   gasFeeZK: string;
   gasFeeZKByUSD: string;
   gasFeeByAqfZK: string;
   gasFeeByAqfZKByUSD: string;
   totalGasFee: string;
   totalGasFeeByUSD: string;
   otherCostsZK: string;
   otherCostsZKByUSD: string;
   initialZK: string;
   initialZKByUSD: string;
   zkUsdPrice: string;
   otherCosts: { name: string; amount: number }[];
   initialZKs: { name: string; amount: number }[];
   ethZkPrice: string;
   zkReceiveWhenSellAllToken: string;
   totalETH: string;
   totalEthByZK: string;
   totalZKInWallet: string;
}

interface ProjectPnLComponentProps {
   project: Project | null;
   projectLoading: boolean;
}

const ProjectPnLComponent: React.FC<ProjectPnLComponentProps> = ({
   project,
   projectLoading,
}) => {
   const [overviewCurrency, setOverviewCurrency] =
      useState<SelectCurrencySyncswap>('ZK');

   const [pnlData, setPnlData] = useState<PnlResponse | null>(null);

   const [fetchingPnl, setFetchingPnl] = useState<boolean>(false);

   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

   const [isModalOpenZK, setIsModalOpenZK] = useState<boolean>(false);

   const params = useParams();

   const isFetchingRef = useRef(false);

   const fetchPnl = async () => {
      if (!params?.id || fetchingPnl || isFetchingRef.current) {
         return;
      }
      setFetchingPnl(true);
      isFetchingRef.current = true;
      try {
         const resPnl = await getPnlSyncswapByProjectID(
            params?.id ? +params.id : 0,
         );
         setFetchingPnl(false);
         isFetchingRef.current = false;
         setPnlData(resPnl?.data?.data);
      } catch (err) {
         setFetchingPnl(false);
         isFetchingRef.current = false;
      }
   };

   useEffect(() => {
      fetchPnl();
      const intervalId = setInterval(fetchPnl, 5000);
      return () => clearInterval(intervalId);
   }, [isModalOpenZK, isModalOpen]);

   return (
      <>
         <div className="flex justify-between items-center gap-3 mt-[7px] mb-[23px] flex-wrap">
            <Title className="!mb-0 max-sm:w-full" level={3}>
               {`${project?.name} Overview`}
            </Title>
            <div className="flex gap-4 items-center max-sm:flex-row-reverse">
               {fetchingPnl && pnlData?.currentZK && (
                  <SyncOutlined spin style={{ fontSize: 24 }} />
               )}
               <Select
                  disabled={projectLoading}
                  value={overviewCurrency}
                  onChange={(value: SelectCurrencySyncswap) =>
                     setOverviewCurrency(value)
                  }
                  className="w-24 shadow-small rounded-[10px]"
               >
                  <Select.Option value="USD">USDT</Select.Option>
                  <Select.Option value="ZK">ZK</Select.Option>
               </Select>
            </div>
         </div>
         <Title level={5} className="!my-[16px]">
            Profit and Loss
         </Title>
         <Card className="mt-4">
            <Row gutter={[20, 20]}>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={pnlData?.currentZK ? false : fetchingPnl}
                  >
                     <HeadingWithTooltipComponent
                        tooltip={`Total ZK + ${project?.name} + ETH On Wallet`}
                     >
                        Current Balance
                     </HeadingWithTooltipComponent>
                     <div className="flex gap-2 items-center justify-center">
                        {overviewCurrency === 'USD' ? (
                           <OverviewInfo
                              amountUsd={pnlData?.currentZKByUSD || '0'}
                           />
                        ) : (
                           <OverviewInfo amountZK={pnlData?.currentZK || '0'} />
                        )}
                     </div>
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={pnlData?.currentZK ? false : fetchingPnl}
                     size={30}
                  >
                     <HeadingWithTooltipComponent>
                        Initial
                     </HeadingWithTooltipComponent>
                     <OverviewInfo
                        onClick={() => setIsModalOpenZK(true)}
                        amountUsd={
                           overviewCurrency === 'USD'
                              ? pnlData?.initialZKByUSD || '0'
                              : undefined
                        }
                        amountZK={
                           overviewCurrency !== 'USD'
                              ? pnlData?.initialZK || '0'
                              : undefined
                        }
                     />
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={pnlData?.currentZK ? false : fetchingPnl}
                  >
                     <HeadingWithTooltipComponent tooltip="Profit = Current Balance - Initial">
                        Profit
                     </HeadingWithTooltipComponent>
                     <div className="flex gap-2 items-center justify-center">
                        {overviewCurrency === 'USD' ? (
                           <OverviewInfo
                              className={overviewTextColorRender(
                                 pnlData?.profitZKByUSD,
                              )}
                              amountUsd={pnlData?.profitZKByUSD || '0'}
                           />
                        ) : (
                           <OverviewInfo
                              className={overviewTextColorRender(
                                 pnlData?.profitZK,
                              )}
                              amountZK={pnlData?.profitZK || '0'}
                           />
                        )}
                     </div>
                  </LoadingComponent>
               </Col>
            </Row>
         </Card>
         <Title level={5} className="!my-[16px]">
            Cost
         </Title>
         <Card>
            <Row gutter={[20, 20]}>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={pnlData?.currentZK ? false : fetchingPnl}
                  >
                     <HeadingWithTooltipComponent>
                        Gas fee Sponsor
                     </HeadingWithTooltipComponent>
                     <div className="flex gap-2 items-center justify-center">
                        {overviewCurrency === 'USD' ? (
                           <OverviewInfo
                              amountUsd={pnlData?.gasFeeZKByUSD || '0'}
                           />
                        ) : (
                           <OverviewInfo amountZK={pnlData?.gasFeeZK || '0'} />
                        )}
                     </div>
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={pnlData?.currentZK ? false : fetchingPnl}
                  >
                     <HeadingWithTooltipComponent>
                        Gas fee by Aquafox
                     </HeadingWithTooltipComponent>
                     <div className="flex gap-2 items-center justify-center">
                        {overviewCurrency === 'USD' ? (
                           <OverviewInfo
                              amountUsd={pnlData?.gasFeeByAqfZKByUSD || '0'}
                           />
                        ) : (
                           <OverviewInfo
                              amountZK={pnlData?.gasFeeByAqfZK || '0'}
                           />
                        )}
                     </div>
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={pnlData?.currentZK ? false : fetchingPnl}
                  >
                     <HeadingWithTooltipComponent tooltip="The total amount paid to execute transactions.">
                        Total gas fee
                     </HeadingWithTooltipComponent>
                     <div className="flex gap-2 items-center justify-center">
                        {overviewCurrency === 'USD' ? (
                           <OverviewInfo
                              amountUsd={pnlData?.totalGasFeeByUSD || '0'}
                           />
                        ) : (
                           <OverviewInfo
                              amountZK={pnlData?.totalGasFee || '0'}
                           />
                        )}
                     </div>
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={pnlData?.currentZK ? false : fetchingPnl}
                     size={30}
                  >
                     <HeadingWithTooltipComponent tooltip="The additional expenses incurred during a transaction or operation that are not explicitly categorized under primary costs.">
                        Other Cost
                     </HeadingWithTooltipComponent>
                     <OverviewInfo
                        onClick={() => setIsModalOpen(true)}
                        amountUsd={
                           overviewCurrency === 'USD'
                              ? pnlData?.otherCostsZKByUSD || '0'
                              : undefined
                        }
                        amountZK={
                           overviewCurrency !== 'USD'
                              ? pnlData?.otherCostsZK || '0'
                              : undefined
                        }
                     />
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent
                     loading={pnlData?.currentZK ? false : fetchingPnl}
                  >
                     <HeadingWithTooltipComponent>
                        Total Cost
                     </HeadingWithTooltipComponent>
                     <div className="flex gap-2 items-center justify-center">
                        {overviewCurrency === 'USD' ? (
                           <OverviewInfo
                              amountUsd={pnlData?.totalCostZKByUSD || '0'}
                           />
                        ) : (
                           <OverviewInfo
                              amountZK={pnlData?.totalCostZK || '0'}
                           />
                        )}
                     </div>
                  </LoadingComponent>
               </Col>
            </Row>
         </Card>
         <UpdateExpenseComponent
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            expenses={pnlData?.otherCosts || []}
            project={project}
            expenseType={ExpenseType.OTHER_COSTS}
         />
         <UpdateExpenseComponent
            isModalOpen={isModalOpenZK}
            setIsModalOpen={setIsModalOpenZK}
            expenses={pnlData?.initialZKs || []}
            project={project}
            expenseType={ExpenseType.INITIAL_ZKS}
         />
      </>
   );
};
export default ProjectPnLComponent;
