/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstanceForPredictHub from '../axios/axiosInstanceForPredictHub';
import { FieldTypeProjectCreateForm } from '../pages/Projects/type/ProjectTypes';

export const getStrategies = (params: any) => {
   return axiosInstanceForPredictHub.get('/v1/strategies', {
      params,
   });
};

export const getDataOverview = (id: any) => {
   return axiosInstanceForPredictHub.get(`/v1/statistics/${id}`);
};

export const createProject = (data: FieldTypeProjectCreateForm) => {
   return axiosInstanceForPredictHub.post('/v1/projects', data);
};

export const fetchToken = (params: any) => {
   return axiosInstanceForPredictHub.get('/v1/contracts/token-info', {
      params,
   });
};

export const getStrateryByID = (id: number) => {
   return axiosInstanceForPredictHub.get(`/v1/strategies/${id}`);
};

export const getDataBot = (id: any) => {
   return axiosInstanceForPredictHub.get(`/v1/statistics/monitoring-bot/${id}`);
};
