import React, { RefObject, useEffect, useState } from 'react';
import { Avatar, Dropdown, MenuProps, message, Modal } from 'antd';
import {
   UserOutlined,
   LogoutOutlined,
   ExclamationCircleFilled,
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { logout, setUser } from '../redux/auth/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { getProfile } from '../services/authenServices';
import { User } from '../redux/auth/type';
import { ERROR_MESSAGE } from '../constants/common';
import LoadingComponent from './LoadingComponent';
import CaretDown from '../assets/svg/caretDown';

interface HeaderComponentProps {
   headerRef: RefObject<HTMLDivElement>;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ headerRef }) => {
   const { user, isAuthenticated } = useSelector(
      (state: RootState) => state.auth,
   );
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [fetchingUser, setFetchingUser] = useState<boolean>(false);

   const appLogout = () => {
      Modal.confirm({
         title: 'Logout',
         icon: <ExclamationCircleFilled />,
         content: 'Do you want to logout?',
         onOk() {
            dispatch(logout());
            navigate('/login');
         },
      });
   };

   useEffect(() => {
      if (isAuthenticated && !user) {
         const fetchUser = async () => {
            try {
               setFetchingUser(true);
               const user = await getProfile();
               dispatch(setUser(user?.data?.data as User));
               setFetchingUser(false);
            } catch (error) {
               setFetchingUser(false);
               message.error(ERROR_MESSAGE);
               appLogout();
            }
         };
         fetchUser();
      }
   }, [isAuthenticated]);

   const items: MenuProps['items'] = [
      {
         className: 'sm:!hidden',
         key: '3',
         label: user?.username || 'user',
      },
      {
         key: '4',
         danger: true,
         label: 'Logout',
         icon: <LogoutOutlined />,
         onClick: appLogout,
      },
   ];

   return (
      <Header className="header !bg-[#F5F5F5]" ref={headerRef}>
         <div className="container flex justify-between items-center">
            <Link to="/project" className="logo-container">
               <img
                  className="logo-img cursor-pointer max-sm:!h-[32px]"
                  src="/images/common/logo_aquaphoenix.png"
               />
            </Link>
            {isAuthenticated && (
               <div className="flex gap-4 items-center">
                  <Dropdown
                     menu={{ items }}
                     placement="bottomRight"
                     className="header-dropdown"
                  >
                     <div className="flex gap-[10px] cursor-pointer items-center leading-0">
                        <Avatar size={38} icon={<UserOutlined />} />
                        <div className="user-name max-sm:hidden">
                           {user?.username || 'user'}
                        </div>
                        <CaretDown />
                     </div>
                  </Dropdown>
               </div>
            )}
            <LoadingComponent fullscreen loading={fetchingUser} />
         </div>
      </Header>
   );
};

export default HeaderComponent;
