function supportsLocalStorage() {
   return typeof Storage !== 'undefined';
}

export const set = (key, value) => {
   if (supportsLocalStorage()) {
      localStorage.setItem(key, JSON.stringify(value));
   }
};

export const get = (key) => {
   if (supportsLocalStorage()) {
      try {
         const value = JSON.parse(localStorage.getItem(key));
         return value;
      } catch (e) {
         localStorage.setItem(key, null);
         return null;
      }
   }
   return undefined;
};

export const clearAll = () => window.localStorage.clear();

export const remove = (key) => window.localStorage.removeItem(key);

export const KEYS = {
   TOKEN: 'TOKEN',
   TOKEN_PREDICTHUB: 'TOKEN_PREDICTHUB',
   MY_ROLES: 'MY_ROLES',
   USER: 'USER',
   DATA_TIMESTAMP: 'DATA_TIMESTAMP',
   DATA_CHART: 'DATA_CHART',
   DATA_CHART_GENERAL: 'DATA_CHART_GENERAL',
   DATA_CHART_TRENDS: 'DATA_CHART_TRENDS',
   MM_NAME: 'MM_NAME',
};

export default {
   set,
   get,
};
