import { ChainsInfo } from '../constants/chains';

export const transactionLinkScan = (
   transactionHash: string,
   chainId: number,
) => {
   return `${ChainsInfo[chainId].scanUrl}/${ChainsInfo[chainId].pathTx}/${transactionHash}`;
};

export const addressLinkScan = (transactionHash: string, chainId: number) => {
   return `${ChainsInfo[chainId].scanUrl}/${ChainsInfo[chainId].pathAddress}/${transactionHash}`;
};

export const transactionDexscreenerScan = (
   pairAddress: string,
   sourceAddress: string,
   chainId: number,
) => {
   if (!pairAddress || !sourceAddress) return '#';
   return `https://dexscreener.com/${ChainsInfo[chainId].name.toLowerCase()}/${pairAddress}?maker=${sourceAddress}`;
};

export const predicthubMarketLink = (id: string) => {
   if (!id) return '#';
   return `https://testnet.predicthub.io/event/${id}`;
};
