import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';

interface LoadingComponentProps {
   loading: boolean;
   fullscreen?: boolean;
   size?: number;
   children?: ReactNode;
   text?: string;
   className?: string;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({
   children,
   loading,
   size = 28,
   text = '',
   fullscreen = false,
   className = '',
}) => {
   const indicator = <LoadingOutlined style={{ fontSize: size }} spin />;
   return (
      <Spin
         spinning={loading}
         indicator={indicator}
         tip={text}
         fullscreen={fullscreen}
      >
         <div className={className}>{children}</div>
      </Spin>
   );
};

export default LoadingComponent;
