import React from 'react';
import { TransactionType } from '../pages/Projects/type/ProjectTypes';
import { TransactionTypes } from '../constants/transactionTypes';

const TransactionTypesRender = (type: TransactionType) => {
   const classNameMap: Record<TransactionType, string> = {
      buy: 'text-sky-500 font-medium text-right',
      BUY: 'text-sky-500 font-medium text-right',
      sell: 'text-red-500 font-medium text-right',
      SELL: 'text-red-500 font-medium text-right',
      transfer_eth: 'text-slate-500 font-medium text-right',
      transfer_token: 'text-slate-500 font-medium text-right',
   };

   return <div className={classNameMap[type]}>{TransactionTypes[type]}</div>;
};

export default TransactionTypesRender;
