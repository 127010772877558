export enum RoleName {
   SUPER_ADMIN = 'superadmin',
   VIEWER_UNISWAP = 'viewer_uniswap',
   MODERATOR_UNISWAP = 'moderator_uniswap',
   VIEWER_PREDICTHUB = 'viewer_predicthub',
   MODERATOR_PREDICTHUB = 'moderator_predicthub',
   VIEWER_ZKSYNC = 'viewer_zksync',
   MODERATOR = 'moderator',
   VIEWER = 'viewer',
 }
 
 export enum PermissionName {
   // project role
   PROJECT_VIEW = 'project.view',
   PROJECT_CREATE = 'project.create',
   PROJECT_MODIFY = 'project.modify',
 
   // monitor
   MONITOR_OVERVIEW = 'monitor.overview',
   MONITOR_USERTRANSACTION = 'monitor.usertransaction',
   MONITOR_TOPBUYER = 'monitor.topbuyer',
   MONITOR_INWALLET = 'monitor.inwallet',
 
   // launch
   LAUNCH_VIEW = 'launch.view',
   LAUNCH_CHANGE_CONFIG = 'launch.changeconfig',
 
   // boost
   BOOST_VIEW = 'boost.view',
   BOOST_CHANGECONFIG = 'boost.changeconfig',
   BOOST_RUNPAUSE = 'boost.runpause',
 
   // take profit
   TAKEPROFIT_VIEW = 'takeprofit.view',
   TAKEPROFIT_ACTION = 'takeprofit.action',
 
   // MM GENERATOR
   MMLIST_VIEW = 'mmlist.view',
   MMLIST_ACTION = 'mmlist.action',
   MMLIST_CREATE = 'mmlist.create',
   MMGENERATOR_VIEW = 'mmgenerator.view',
   MMGENERATOR_MODIFY = 'mmgenerator.modify',
   MMGENERATOR_FILE = 'mmgenerator.file',
   MMGENERATOR_SNIPE = 'mmgenerator.snipe',
   MMGENERATOR_BUDGET = 'mmgenerator.budget',
 
   // THIRD PARTY
   ACCESS_PREDICTHUB = 'access.predicthub',
   ACCESS_UNISWAP = 'access.uniswap',
   ACCESS_SYNCSWAP = 'access.syncswap',
 
   // ACCOUNT
   ACCOUNT_CREATE = 'account.create',
   ACCOUNT_MODIFY = 'account.modify',
 }