/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skeleton, Tooltip } from 'antd';
import React from 'react';
import CountUp from 'react-countup';
import EthSvg from '../assets/svg/eth';
import numeral from 'numeral';
import formatTokenPrice from '../utils/formatTokenPrice';
import EditPencil from '../assets/svg/editPencil';
import ZKSyncSvg from '../assets/svg/zksync';

interface OverviewInfo {
   amountEth?: string | undefined;
   amountUsd?: string | undefined;
   amountZK?: string | undefined;
   tokenPriceUSD?: string | undefined;
   tokenPriceETH?: string | undefined;
   rangePrice?: [string, string] | undefined;
   amountWithoutCurrency?: string | undefined;
   className?: string;
   loading?: boolean;
   fontSize?: number;
   classNameETH?: string;
   usdSymbolHidden?: boolean;
   onClick?: (data?: any) => void;
}

const OverviewInfo: React.FC<OverviewInfo> = ({
   amountEth,
   amountZK,
   amountUsd,
   tokenPriceUSD,
   tokenPriceETH,
   rangePrice,
   amountWithoutCurrency,
   className,
   loading = false,
   fontSize = 24,
   classNameETH,
   usdSymbolHidden = false,
   onClick,
}) => {
   return !loading ? (
      <div
         onClick={onClick}
         className={`statistic-number-component !flex gap-2 items-center ${className} ${onClick ? 'cursor-pointer' : ''}`}
      >
         {amountEth && (
            <Tooltip
               placement="bottom"
               title={
                  amountEth
                     ? `${numeral(amountEth ? +amountEth : 0).format('0,0.[000000]')}`
                     : ''
               }
            >
               <div
                  className={`flex items-center gap-2 truncate  ${classNameETH}`}
                  style={{ fontSize }}
               >
                  <div>{<EthSvg />}</div>
                  <CountUp
                     // className="truncate "
                     end={amountEth ? +amountEth : 0}
                     duration={0.8}
                     separator=","
                     decimals={amountEth && +amountEth % 1 !== 0 ? 6 : 0}
                     decimal="."
                  />
               </div>
            </Tooltip>
         )}
         {amountZK && (
            <Tooltip
               placement="bottom"
               title={
                  amountZK
                     ? `${numeral(amountZK ? +amountZK : 0).format('0,0.[00]')}`
                     : ''
               }
            >
               <div
                  className={`flex items-center gap-2 truncate  ${classNameETH}`}
                  style={{ fontSize }}
               >
                  <div>{<ZKSyncSvg />}</div>
                  <CountUp
                     // className="truncate "
                     end={amountZK ? +amountZK : 0}
                     duration={0.8}
                     separator=","
                     decimals={amountZK && +amountZK % 1 !== 0 ? 2 : 0}
                     decimal="."
                  />
               </div>
            </Tooltip>
         )}
         {amountUsd && (
            <Tooltip
               placement="bottom"
               title={
                  +amountUsd
                     ? `${numeral(amountUsd ? +amountUsd : 0).format('0,0.[00]')}`
                     : ''
               }
            >
               <div className="truncate " style={{ fontSize }}>
                  {!usdSymbolHidden && '$ '}
                  <CountUp
                     className="truncate "
                     end={amountUsd ? +amountUsd : 0}
                     duration={0.8}
                     separator=","
                     decimals={amountUsd && +amountUsd % 1 !== 0 ? 2 : 0}
                     decimal="."
                  />
               </div>
            </Tooltip>
         )}
         {rangePrice && (
            <Tooltip
               placement="bottom"
               title={
                  rangePrice[0] && rangePrice[1]
                     ? `${numeral(rangePrice[0] ? +rangePrice[0] : 0).format('0,0.[00]')} => ${numeral(rangePrice[1] ? +rangePrice[1] : 0).format('0,0.[00]')}`
                     : ''
               }
            >
               <div
                  style={{ fontSize }}
                  className="flex gap-2 items-center truncate"
               >
                  $
                  <CountUp
                     className="truncate"
                     end={rangePrice[0] ? +rangePrice[0] : 0}
                     duration={0.8}
                     separator=","
                     decimals={
                        rangePrice[0] && +rangePrice[0] % 1 !== 0 ? 2 : 0
                     }
                     decimal="."
                  />
                  <div>
                     <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M8 6L8 2L10 2L16 8L10 14L8 14L8 10L-1.74845e-07 10L-3.01991e-07 6L8 6Z"
                           fill="#000000"
                        />
                     </svg>
                  </div>
                  <CountUp
                     className="truncate"
                     end={rangePrice[1] ? +rangePrice[1] : 0}
                     duration={0.8}
                     separator=","
                     decimals={
                        rangePrice[1] && +rangePrice[1] % 1 !== 0 ? 2 : 0
                     }
                     decimal="."
                  />
               </div>
            </Tooltip>
         )}
         {amountWithoutCurrency && (
            <Tooltip
               placement="bottom"
               title={
                  amountWithoutCurrency
                     ? `${numeral(amountWithoutCurrency ? +amountWithoutCurrency : 0).format('0,0.[00]')}`
                     : ''
               }
            >
               <div className="truncate " style={{ fontSize }}>
                  <CountUp
                     className="truncate "
                     end={amountWithoutCurrency ? +amountWithoutCurrency : 0}
                     duration={0.8}
                     separator=","
                     decimals={
                        amountWithoutCurrency &&
                        +amountWithoutCurrency % 1 !== 0
                           ? 2
                           : 0
                     }
                     decimal="."
                  />
               </div>
            </Tooltip>
         )}
         {tokenPriceETH && (
            <div
               className={`flex items-center gap-2 truncate  ${classNameETH}`}
               style={{ fontSize }}
            >
               <div>{<EthSvg />}</div>
               {formatTokenPrice(tokenPriceETH)}
            </div>
         )}
         {tokenPriceUSD && (
            <div className="truncate " style={{ fontSize }}>
               {!usdSymbolHidden && '$ '}
               {formatTokenPrice(tokenPriceUSD)}{' '}
            </div>
         )}
         {onClick && (
            <div className="h-[21px] w-[21px] flex items-center justify-center rounded-[4px] border border-dashed border-[#0CA7EE]">
               <EditPencil />
            </div>
         )}
      </div>
   ) : (
      <Skeleton.Input active />
   );
};

export default OverviewInfo;
