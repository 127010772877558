import { TransactionType } from '../pages/Projects/type/ProjectTypes';

const buySellTextColorRender = (value: TransactionType | '') => {
   switch (value) {
      case 'buy':
         return 'max-sm:text-[#15BD7A]';
      case 'sell':
         return 'max-sm:text-[#EB3737]';
      default:
         return '';
   }
};

export default buySellTextColorRender;
