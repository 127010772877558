/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { PermissionName, RoleName } from "../../constants/permissions";


interface RolePermissions {
   [role: string]: string[];
}

interface PermissionState {
   rolesPermissions: RolePermissions;
}

const initialState: PermissionState = {
   rolesPermissions: {
      [RoleName.SUPER_ADMIN]: Object.values(PermissionName),
      [RoleName.MODERATOR]: [
        // Project
        PermissionName.PROJECT_VIEW,
        PermissionName.PROJECT_MODIFY,
        PermissionName.PROJECT_CREATE,

        // Monitor
        PermissionName.MONITOR_OVERVIEW,
        PermissionName.MONITOR_USERTRANSACTION,
        PermissionName.MONITOR_TOPBUYER,
        PermissionName.MONITOR_INWALLET,

        // Launch
        PermissionName.LAUNCH_VIEW,
        PermissionName.LAUNCH_CHANGE_CONFIG,

        // Boost
        PermissionName.BOOST_VIEW,
        PermissionName.BOOST_CHANGECONFIG,
        PermissionName.BOOST_RUNPAUSE,

        // Take Profit
        PermissionName.TAKEPROFIT_VIEW,
        PermissionName.TAKEPROFIT_ACTION,

        // MM Generator
        PermissionName.MMLIST_VIEW,
        PermissionName.MMLIST_ACTION,
        PermissionName.MMLIST_CREATE,
        PermissionName.MMGENERATOR_VIEW,
        PermissionName.MMGENERATOR_MODIFY,
        PermissionName.MMGENERATOR_FILE,
        PermissionName.MMGENERATOR_SNIPE,
        PermissionName.MMGENERATOR_BUDGET,

        // Third Party
        PermissionName.ACCESS_PREDICTHUB,
        PermissionName.ACCESS_UNISWAP,
      ],
      [RoleName.MODERATOR_UNISWAP]: [
        // Project
        PermissionName.PROJECT_VIEW,
        PermissionName.PROJECT_MODIFY,
        PermissionName.PROJECT_CREATE,

        // Monitor
        PermissionName.MONITOR_OVERVIEW,
        PermissionName.MONITOR_USERTRANSACTION,
        PermissionName.MONITOR_TOPBUYER,
        PermissionName.MONITOR_INWALLET,

        // Launch
        PermissionName.LAUNCH_VIEW,
        PermissionName.LAUNCH_CHANGE_CONFIG,

        // Boost
        PermissionName.BOOST_VIEW,
        PermissionName.BOOST_CHANGECONFIG,
        PermissionName.BOOST_RUNPAUSE,

        // Take Profit
        PermissionName.TAKEPROFIT_VIEW,
        PermissionName.TAKEPROFIT_ACTION,

        // MM Generator
        PermissionName.MMLIST_VIEW,
        PermissionName.MMLIST_ACTION,
        PermissionName.MMLIST_CREATE,
        PermissionName.MMGENERATOR_VIEW,
        PermissionName.MMGENERATOR_MODIFY,
        PermissionName.MMGENERATOR_FILE,
        PermissionName.MMGENERATOR_SNIPE,
        PermissionName.MMGENERATOR_BUDGET,

        // Third Party
        PermissionName.ACCESS_UNISWAP,
      ],
      [RoleName.MODERATOR_PREDICTHUB]: [
        // Project
        PermissionName.PROJECT_VIEW,
        PermissionName.PROJECT_MODIFY,
        PermissionName.PROJECT_CREATE,

        // Monitor
        PermissionName.MONITOR_OVERVIEW,
        PermissionName.MONITOR_USERTRANSACTION,
        PermissionName.MONITOR_TOPBUYER,
        PermissionName.MONITOR_INWALLET,

        // Launch
        PermissionName.LAUNCH_VIEW,
        PermissionName.LAUNCH_CHANGE_CONFIG,

        // Boost
        PermissionName.BOOST_VIEW,
        PermissionName.BOOST_CHANGECONFIG,
        PermissionName.BOOST_RUNPAUSE,

        // Take Profit
        PermissionName.TAKEPROFIT_VIEW,
        PermissionName.TAKEPROFIT_ACTION,

        // MM Generator
        PermissionName.MMLIST_VIEW,
        PermissionName.MMLIST_ACTION,
        PermissionName.MMLIST_CREATE,
        PermissionName.MMGENERATOR_VIEW,
        PermissionName.MMGENERATOR_MODIFY,
        PermissionName.MMGENERATOR_FILE,
        PermissionName.MMGENERATOR_SNIPE,
        PermissionName.MMGENERATOR_BUDGET,

        // Third Party
        PermissionName.ACCESS_PREDICTHUB,
      ],
      [RoleName.VIEWER]: [
        // Project
        PermissionName.PROJECT_VIEW,

        // Monitor
        PermissionName.MONITOR_OVERVIEW,
        PermissionName.MONITOR_USERTRANSACTION,
        PermissionName.MONITOR_TOPBUYER,
        PermissionName.MONITOR_INWALLET,

        // Launch
        PermissionName.LAUNCH_VIEW,

        // MM Generator
        PermissionName.MMLIST_VIEW,
        PermissionName.MMGENERATOR_VIEW,

        // Third Party
        PermissionName.ACCESS_PREDICTHUB,
        PermissionName.ACCESS_UNISWAP,
      ],
      [RoleName.VIEWER_UNISWAP]: [
        // Project
        PermissionName.PROJECT_VIEW,

        // Monitor
        PermissionName.MONITOR_OVERVIEW,
        PermissionName.MONITOR_USERTRANSACTION,
        PermissionName.MONITOR_TOPBUYER,
        PermissionName.MONITOR_INWALLET,

        // MM Generator
        PermissionName.MMLIST_VIEW,
        PermissionName.MMGENERATOR_VIEW,

        // Third Party
        PermissionName.ACCESS_UNISWAP,
      ],
      [RoleName.VIEWER_PREDICTHUB]: [
        // Project
        PermissionName.PROJECT_VIEW,

        // Monitor
        PermissionName.MONITOR_OVERVIEW,
        PermissionName.MONITOR_USERTRANSACTION,
        PermissionName.MONITOR_TOPBUYER,
        PermissionName.MONITOR_INWALLET,

        // MM Generator
        PermissionName.MMLIST_VIEW,
        PermissionName.MMGENERATOR_VIEW,

        // Third Party
        PermissionName.ACCESS_PREDICTHUB,
      ],
      [RoleName.VIEWER_ZKSYNC]: [
        // Project
        PermissionName.PROJECT_VIEW,

        // Monitor
        PermissionName.MONITOR_OVERVIEW,
        PermissionName.MONITOR_USERTRANSACTION,
        PermissionName.MONITOR_TOPBUYER,
        PermissionName.MONITOR_INWALLET,

        // Third Party
        PermissionName.ACCESS_SYNCSWAP,
      ],
      
   },
};

const permissionSlice = createSlice({
   name: 'permissions',
   initialState,
   reducers: {
   },
});


export default permissionSlice.reducer;
