import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import './styles/index.scss';
import { ConfigProvider, Layout, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import {
   BrowserRouter as Router,
   Routes,
   Route,
   useNavigate,
   Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import ProjectsRouterComponent from './pages/Projects/router/ProjectsRouterComponent';
import LoginComponent from './pages/Login/LoginComponent';
import { setupInterceptors } from './axios/axiosInstance';
import ChartGeneratorRouterComponent from './pages/ChartGenerator2/router/ChartGeneratorRouterComponent';
import StrategysRouterComponent from './pages/Projects/router/StrategysRouterComponent';
import ProjectsSyncswapRouterComponent from './pages/Projects/router/ProjectsSyncswapRouterComponent';

message.config({
   // top: 100,
   // duration: 2,
   maxCount: 1,
   // rtl: true,
   // prefixCls: 'my-message',
});

type ThemeData = {
   borderRadius: number;
   colorPrimary: string;
   controlHeight: number;
   boxShadow: string;
   Button?: {
      colorPrimary: string;
      algorithm?: boolean;
   };
   Divider?: {
      fontSize: number;
   };
   Table?: {
      borderColor: string;
      headerBg: string;
   };
};

const antDefaultConfig: ThemeData = {
   borderRadius: 10,
   colorPrimary: '#0CA7EE',
   boxShadow: '0px 2px 6px 0px rgba(59, 59, 66, 0.36)',
   controlHeight: 35,
   Button: {
      colorPrimary: '#0CA7EE',
      algorithm: true,
   },
   Divider: {
      fontSize: 20,
   },
   Table: {
      borderColor: '#E7E7F6',
      headerBg: '#FAFAFC',
   },
};

function App() {
   // const {
   //    token: {  },
   // } = theme.useToken();
   const headerRef = useRef<HTMLDivElement>(null);
   const footerRef = useRef<HTMLDivElement>(null);
   const [layoutHeight, setLayoutHeight] = useState<number>(0);

   useEffect(() => {
      if (headerRef.current && footerRef.current) {
         const headerHeight = headerRef.current.offsetHeight;
         const footerHeight = footerRef.current.offsetHeight;
         setLayoutHeight(headerHeight + footerHeight);
      }
   }, [headerRef.current?.offsetHeight, footerRef.current?.offsetHeight]);

   const SetupInterceptors = () => {
      const navigate = useNavigate();
      setupInterceptors(store, navigate);
      return null;
   };

   return (
      <Router>
         <Layout>
            <Provider store={store}>
               <SetupInterceptors />
               <ConfigProvider
                  // componentSize="large"
                  theme={{
                     token: {
                        colorPrimary: antDefaultConfig.colorPrimary,
                        borderRadius: antDefaultConfig.borderRadius,
                        controlHeight: antDefaultConfig.controlHeight,
                     },
                     components: {
                        Button: {
                           colorPrimary: antDefaultConfig.Button?.colorPrimary,
                           algorithm: antDefaultConfig.Button?.algorithm,
                        },
                        Divider: {
                           fontSize: antDefaultConfig.Divider?.fontSize,
                        },
                        Table: {
                           headerBg: antDefaultConfig.Table?.headerBg,
                           borderRadius: 10,
                        },
                     },
                  }}
               >
                  <HeaderComponent headerRef={headerRef} />
                  <Content
                     className="main-content"
                     style={{
                        // minHeight: `calc(100vh - ${layoutHeight}px)`,
                        // background: 'url("/images/background/hero-bg.png")',
                        // backgroundRepeat: 'no-repeat',
                        // backgroundSize: 'cover',
                        background: '#fafafc',
                     }}
                  >
                     <div
                        style={{
                           minHeight: `calc(100vh - ${layoutHeight}px)`,
                        }}
                     >
                        <Routes>
                           <Route
                              path="/login"
                              element={
                                 <PublicRoute element={<LoginComponent />} />
                              }
                           />
                           <Route
                              path="/project/*"
                              element={
                                 <PrivateRoute
                                    element={<ProjectsRouterComponent />}
                                 />
                              }
                           />

                           <Route
                              path="/project-syncswap/*"
                              element={
                                 <PrivateRoute
                                    element={
                                       <ProjectsSyncswapRouterComponent />
                                    }
                                 />
                              }
                           />

                           <Route
                              path="/strategy/*"
                              element={
                                 <PrivateRoute
                                    element={<StrategysRouterComponent />}
                                 />
                              }
                           />

                           <Route
                              path="*"
                              element={<Navigate to="/project" replace />}
                           />
                           <Route
                              path="/chart-generator/*"
                              element={
                                 <PrivateRoute
                                    element={<ChartGeneratorRouterComponent />}
                                 />
                              }
                           />
                        </Routes>
                     </div>
                     <FooterComponent footerRef={footerRef} />
                  </Content>
               </ConfigProvider>
            </Provider>
         </Layout>
      </Router>
   );
}

export default App;
