import React from 'react';

interface EditPencilProps {
   width?: number;
   height?: number;
}

const EditPencil: React.FC<EditPencilProps> = ({ width = 17, height = 17 }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
   >
      <path
         d="M14.5403 5.08561L11.7478 2.29249C11.6549 2.1996 11.5446 2.12592 11.4233 2.07565C11.302 2.02539 11.1719 1.99951 11.0406 1.99951C10.9092 1.99951 10.7792 2.02539 10.6578 2.07565C10.5365 2.12592 10.4262 2.1996 10.3334 2.29249L2.6265 9.99999C2.53324 10.0925 2.4593 10.2026 2.40898 10.324C2.35866 10.4453 2.33296 10.5755 2.33338 10.7069V13.5C2.33338 13.7652 2.43874 14.0196 2.62627 14.2071C2.81381 14.3946 3.06816 14.5 3.33338 14.5H6.1265C6.25787 14.5004 6.38802 14.4747 6.50937 14.4244C6.63072 14.3741 6.74086 14.3001 6.83338 14.2069L14.5403 6.49999C14.6331 6.40712 14.7068 6.29687 14.7571 6.17553C14.8074 6.05419 14.8332 5.92414 14.8332 5.7928C14.8332 5.66146 14.8074 5.5314 14.7571 5.41006C14.7068 5.28872 14.6331 5.17847 14.5403 5.08561ZM12.3334 7.29249L9.54025 4.49999L11.0403 2.99999L13.8334 5.79249L12.3334 7.29249Z"
         fill="#0CA7EE"
      />
   </svg>
);

export default EditPencil;
