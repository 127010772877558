export const Chains: { [key: number]: string } = {
   1: 'Ethereum',
   8453: 'Base',
   324: 'ZKsync',
};

export const ChainsInfo: {
   [key: number]: {
      name: string;
      decimals: number;
      scanUrl: string;
      pathTx: string;
      pathAddress: string;
   };
} = {
   1: {
      name: 'Ethereum',
      decimals: 18,
      scanUrl: 'https://etherscan.io',
      pathTx: 'tx',
      pathAddress: 'address',
   },
   8453: {
      name: 'Base',
      decimals: 18,
      scanUrl: 'https://basescan.org',
      pathTx: 'tx',
      pathAddress: 'address',
   },
   324: {
      name: 'ZKsync',
      decimals: 18,
      scanUrl: 'https://explorer.zksync.io',
      pathTx: 'tx',
      pathAddress: 'address',
   },
};

export enum SupportChain {
   Ethereum = 1,
   Base = 8453,
   ZKsync = 324,
}
