import {
   Action,
   ThunkAction,
   combineReducers,
   configureStore,
} from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import permissionReducer from './permission/permissionSlice';
import projectsReducer from './projects/projectsSlice';
import straterysReducer from './projects/strategysSlice';

import { useDispatch } from 'react-redux';

const rootReducer = combineReducers({
   auth: authReducer,
   permissions: permissionReducer,
   projects: projectsReducer,
   straterys: straterysReducer,
});

const store = configureStore({
   reducer: {
      auth: authReducer,
      permissions: permissionReducer,
      projects: projectsReducer,
      straterys: straterysReducer,
   },
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>;
