import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import {
   getProjectByID,
} from '../../services/projectServices';
import { Project } from './type';

type ProjectState = {
   project: Project | null;
   loading: boolean;
   error: string | null;
};

const initialState: ProjectState = {
   project: null,
   loading: false,
   error: null,
};

const projectsSlice = createSlice({
   name: 'projects',
   initialState,
   reducers: {
      setProject(state, action: PayloadAction<Project | null>) {
         state.project = action.payload;
         state.loading = false;
         state.error = null;
      },
      setLoading(state, action: PayloadAction<boolean>) {
         state.loading = action.payload;
      },
      setError(state, action: PayloadAction<string | null>) {
         state.error = action.payload;
         state.loading = false;
      },
   },
});

export const { setProject, setLoading, setError } = projectsSlice.actions;

export default projectsSlice.reducer;

export const fetchProjectById =
   (id: number, navigate: (path: string) => void): AppThunk =>
   async (dispatch) => {
      dispatch(setLoading(true));
      try {
         const resProj = await getProjectByID(id);
         const project = resProj?.data?.data as Project;
         dispatch(setProject(project));
      } catch (err) {
         navigate('/project');
         dispatch(setProject(null));
      } finally {
         dispatch(setLoading(false));
      }
   };
