/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axios/axiosInstance';
import { FieldTypeProjectCreateForm } from '../pages/Projects/type/ProjectTypes';

export const getProjects = (params: any) => {
   return axiosInstance.get('/v1/projects', {
      params,
   });
};

export const createProject = (data: FieldTypeProjectCreateForm) => {
   return axiosInstance.post('/v1/projects', data);
};

export const fetchToken = (params: any) => {
   return axiosInstance.get('/v1/contracts/token-info', {
      params,
   });
};

export const getProjectByID = (id: number) => {
   return axiosInstance.get(`/v1/projects/${id}`);
};

export const getPnlByProjectID = (id: number) => {
   return axiosInstance.get(`/v1/pnl/${id}`);
};

export const getPnlSyncswapByProjectID = (id: number) => {
   return axiosInstance.get(`/v1/pnl/zk/${id}`);
};

export const getTotalInWalletByProjectID = (id: number) => {
   return axiosInstance.get(`/v1/pnl/in-wallets/${id}`);
};

export const getTotalInWalletSyncswapByProjectID = (id: number) => {
   return axiosInstance.get(`/v1/pnl/zk/in-wallets/${id}`);
};

export const getPnlTopbuyer = (id: number) => {
   return axiosInstance.get(`/v1/pnl/top-buyer/${id}`);
};

export const getPnlUserTx = (id: number) => {
   return axiosInstance.get(`/v1/pnl/user-transaction/${id}`);
};

export const updateProject = (data: any, id: number) => {
   return axiosInstance.put(`/v1/projects/${id}`, data);
};

export const getPnlUserTxZK = (id: number) => {
   return axiosInstance.get(`/v1/pnl/zk/user-transaction/${id}`);
};