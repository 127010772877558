/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Empty, Form, Input, message, Modal } from 'antd';
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import InputNumberComponent from '../../../components/InputNumberComponent';
import { updateProject } from '../../../services/projectServices';
import { Project } from '../../../redux/projects/type';
import { validatePositiveNumber } from '../../../utils/validatePositiveNumber';
import { ERROR_MESSAGE } from '../../../constants/common';
import CancelCircle from '../../../assets/svg/cancelCircle';
import ZKSyncSvg from '../../../assets/svg/zksync';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';

export enum ExpenseType {
   OTHER_COSTS = 'other-costs',
   INITIAL_ZKS = 'initial-zks',
}
interface UpdateExpenseComponentPros {
   isModalOpen: boolean;
   setIsModalOpen: (value: boolean) => void;
   expenses: { name: string; amount: number }[];
   project: Project | null;
   expenseType?: ExpenseType;
}

const UpdateExpenseComponent: React.FC<UpdateExpenseComponentPros> = ({
   isModalOpen,
   setIsModalOpen,
   expenses,
   project,
   expenseType = ExpenseType.OTHER_COSTS,
}) => {
   const [isSaving, setIsSaving] = useState<boolean>(false);
   const { hasPermission } = usePermissions();

   useEffect(() => {
      if (isModalOpen) {
         form.setFieldsValue({
            expenses: [...expenses],
         });
      } else {
         form.resetFields();
      }
   }, [isModalOpen]);

   const handleOk = () => {
      form.submit();
      if (!form.getFieldValue('expenses')?.length && expenses?.length) {
         Modal.confirm({
            title:
               expenseType === ExpenseType.OTHER_COSTS
                  ? 'Other costs'
                  : 'Initial ZKs',
            icon: <ExclamationCircleFilled />,
            content: `Do you want to clear all ${expenseType === ExpenseType.OTHER_COSTS ? 'other costs' : 'initial ZKs'} ?`,
            onOk() {
               updateExpenses([]);
            },
         });
      }

      if (!form.getFieldValue('expenses')?.length && !expenses?.length) {
         message.error('Please create at least one expense!');
      }
   };

   const handleCancel = () => {
      form.resetFields();
      setIsModalOpen(false);
   };

   const [form] = Form.useForm();

   const onFinish = async (values: any) => {
      const data = values?.['expenses'] || [];
      updateExpenses(data);
   };

   const updateExpenses = async (data: any) => {
      setIsSaving(true);
      try {
         const targetKey =
            expenseType === ExpenseType.OTHER_COSTS
               ? 'otherCosts'
               : 'initialZKs';

         const savingData = {
            projectStat: {
               [targetKey]: [...data],
            },
         };
         await updateProject(savingData, project?.id ? project.id : 0);
         message.success(
            `Update ${expenseType === ExpenseType.OTHER_COSTS ? 'other cost' : 'initial zks'} successfully!`,
         );
         setIsSaving(false);
         handleCancel();
      } catch (error: any) {
         const err = error?.response?.data?.message[0];
         console.log(err);
         setIsSaving(false);
         message.error(ERROR_MESSAGE);
      }
   };

   return (
      <Modal
         title={
            expenseType === ExpenseType.OTHER_COSTS
               ? 'Other Cost'
               : 'Initial ZKs'
         }
         open={isModalOpen}
         okText="Save"
         onOk={handleOk}
         className="pb-0"
         onCancel={handleCancel}
         cancelButtonProps={{
            className: `${hasPermission(PermissionName.PROJECT_MODIFY) ? '' : 'hidden'}`,
         }}
         okButtonProps={{
            className: `${hasPermission(PermissionName.PROJECT_MODIFY) ? '' : 'hidden'}`,
            loading: isSaving,
         }}
      >
         <Form
            className="mt-6"
            form={form}
            name="dynamic_form"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            disabled={!hasPermission(PermissionName.PROJECT_MODIFY)}
         >
            {!expenses?.length &&
               !hasPermission(PermissionName.PROJECT_MODIFY) && <Empty />}
            <Form.List
               name="expenses"
               rules={[
                  {
                     validator: async (_, fields) => {
                        if (!fields || fields.length === 0) {
                           return Promise.reject(
                              new Error('Please create at least one expense!'),
                           );
                        }
                     },
                  },
               ]}
            >
               {(fields, { add, remove }) => (
                  <>
                     {fields.map(({ key, name, ...restField }) => (
                        <div
                           key={key}
                           className="flex justify-between items-center w-full gap-3 antd-space-custom"
                        >
                           <Form.Item
                              {...restField}
                              className="w-full"
                              name={[name, 'name']}
                              rules={[
                                 {
                                    required: true,
                                    message: 'Please input name!',
                                 },
                              ]}
                           >
                              <Input placeholder="Name" className="w-full" />
                           </Form.Item>
                           <Form.Item
                              {...restField}
                              className="w-full"
                              name={[name, 'amount']}
                              rules={[
                                 {
                                    required: true,
                                    message: 'Please input amount!',
                                 },
                                 {
                                    type: 'number',
                                    message: 'Amount must be a number!',
                                 },
                                 {
                                    validator: validatePositiveNumber,
                                 },
                              ]}
                           >
                              <InputNumberComponent
                                 prefix={<ZKSyncSvg width={20} height={20} />}
                                 allowDecimal
                                 placeholder="Amount"
                                 className="w-full"
                              />
                           </Form.Item>
                           {hasPermission(PermissionName.PROJECT_MODIFY) && (
                              <div className="relative h-full w-[50px]">
                                 <div
                                    className="absolute bottom-[-1px] right-0 cursor-pointer"
                                    onClick={() => remove(name)}
                                 >
                                    <CancelCircle />
                                 </div>
                              </div>
                           )}
                        </div>
                     ))}

                     {hasPermission(PermissionName.PROJECT_MODIFY) && (
                        <Form.Item>
                           <Button
                              type="text"
                              className="text-primary"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                           >
                              Add New
                           </Button>
                        </Form.Item>
                     )}
                  </>
               )}
            </Form.List>
         </Form>
      </Modal>
   );
};

export default UpdateExpenseComponent;
